import React from "react"
// import { Link } from "gatsby"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"
import "../styles/team.css"
import ReactHtmlParser from 'react-html-parser';

const SecondPage = ({ data }) => {

    return(
    <>
    <SEO title="Teamet" />
    <section id="team">
        {data.allDirectusTeamMain.edges.length > 0 &&
        <>
          <h2 className="heading">Teamet</h2>
          <section className="team--container">
          {data.allDirectusTeamMain.edges.map(edge => (
              <article key={edge.node.id}>
                  <section className="first">
                      <Img fluid={edge.node.image.localFile.childImageSharp.fluid}/>
                      <div className="text">
                          <h2>{edge.node.name}</h2>
                          <p>{edge.node.position}</p>
                          <a href={"mailto:" + edge.node.email}>{edge.node.email}</a>
                      </div>
                  </section>
                  <section className="descr">
                    <p>{ReactHtmlParser(edge.node.description)}</p>
                  </section>
              </article>
          ))}
          </section>
        </> 
        }
        <h2 className="heading">Holdet</h2>
        <section className="team--container-small">
          {data.allDirectusTeamSecondary.edges.map(edge => (
            <article key={edge.node.id} className="team-small">
              <Img fluid={edge.node.image.localFile.childImageSharp.fluid} className="image-holder"/>
              <div className="text">
                <h2>{edge.node.name}</h2>
                <p>{edge.node.position}</p>
              </div>
            </article>
          ))}
        </section>
    </section>
  </>
)}

export default SecondPage

export const query = graphql`
  {
    allDirectusTeamMain {
      edges {
        node {
          email
          id
          image {
            localFile {
              childImageSharp {
                fluid(quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp                
                }
              }
            }
          }
          description
          name
          position
        }
      }
    }
    allDirectusTeamSecondary (filter: {status: {eq: "published"}}) {
      edges {
        node {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 65, maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp              
                }
              }
            }
          }
          id
          name
          position
        }
      }
    }
  }
  
`
